import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "Home",
      path: "/",
      component: () => import("@/views/Home.vue"),
    },
    {
      name: "About",
      path: "/magunkrol",
      component: () => import("@/views/About.vue"),
    },
    {
      name: "Services",
      path: "/szolgaltatasaink",
      alias: "/araink",
      component: () => import("@/views/Services.vue"),
    },
    {
      name: "Service",
      path: "/szolgaltatasaink/berles-:slug",
      component: () => import("@/views/Service.vue"),
    },
    {
      name: "Cars",
      path: "/jarmuveink",
      component: () => import("@/views/Cars.vue"),
    },
    {
      name: "Car",
      path: "/jarmuveink/:slug",
      component: () => import("@/views/Car.vue"),
    },
    {
      name: "Gallery",
      path: "/galeria",
      component: () => import("@/views/Gallery.vue"),
    },
    {
      name: "Faq",
      path: "/gyakran-ismetelt-kerdesek",
      component: () => import("@/views/Faq.vue"),
    },
    {
      name: "BlogPosts",
      path: "/blog",
      component: () => import("@/views/BlogPosts.vue"),
    },
    {
      name: "BlogPost",
      path: "/blog/:slug",
      component: () => import("@/views/BlogPost.vue"),
    },
    {
      name: "Contact",
      path: "/kapcsolat",
      component: () => import("@/views/Contact.vue"),
    },
    {
      name: "HowToRent",
      path: "/hogyan-zajlik-az-elektromos-kisauto-kolcsonzes",
      component: () => import("@/views/HowToRent.vue"),
    },
    {
      name: "NotFound",
      path: "/:pathMatch(.*)*",
      component: () => import("@/views/NotFound.vue"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0, left: 0 };
    }
  },
});

export default router;
