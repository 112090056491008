export default {
  methods: {
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    formatPrice(number) {
      return this.formatNumber(number) + " Ft";
    },
  },
};
