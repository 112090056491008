export default {
  methods: {
    getGridSm(length, minGrid = 1, maxGrid = 12, defaultGrid = 4) {
      let grid = defaultGrid;

      switch (length) {
        case 1:
          grid = 12;
          break;
        case 2:
          grid = 6;
          break;
        case 3:
          grid = 4;
          break;
        case 4:
          grid = 3;
          break;
        case 5:
          grid = "auto";
          break;
        case 6:
          grid = 2;
          break;
        case 7:
          grid = "auto";
          break;
        case 8:
          grid = 3;
          break;
        case 9:
          grid = 4;
          break;
        default:
          grid = defaultGrid;
          break;
      }

      if (grid !== "auto" && grid < minGrid) {
        return minGrid;
      }

      if (grid !== "auto" && grid > maxGrid) {
        return maxGrid;
      }

      return grid;
    },
  },
};
