import { createApp } from "vue";
import { BootstrapVue3 } from "bootstrap-vue-3";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import App from "./App.vue";
import router from "./router";
import pageTitle from "@/scripts/pageTitle";
import formatNumber from "@/scripts/formatNumber";
import calculateGrid from "@/scripts/calculateGrid";
import data from "@/scripts/data";

const app = createApp(App);
app.use(BootstrapVue3);
app.use(router);
app.mixin(pageTitle);
app.mixin(formatNumber);
app.mixin(calculateGrid);
app.mixin(data);
app.component("BootstrapIcon", BootstrapIcon);
app.mount("#app");
