export default {
  data() {
    return {
      baseTitle: "KicsiKocsik elektromos gyerekautó kölcsönző",
    };
  },

  created() {
    const title = this.getTitle(this);

    if (title) {
      document.title = title + " | " + this.baseTitle;
    } else {
      document.title = this.baseTitle;
    }

    this.currentUrl = window.location.href;
  },

  methods: {
    getTitle(vm) {
      const { title } = vm.$options;

      if (title) {
        return typeof title === "function" ? title.call(vm) : title;
      }
    },
  },
};
